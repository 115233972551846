const config = {
  buylinks: {
    italianLink:
      "https://www.amazon.co.uk/Italian-Affair-Caroline-Montague/dp/1409180786/",
    parisLink:
      "https://www.amazon.co.uk/Untitled-Montague-2-Caroline/dp/1409180824",
    spanishLink:
      "https://www.amazon.co.uk/gp/product/B08HPHVT1Z/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i2"
  },
  staticRevews: [
    {
      id: 1,
      review: `I was stunned and enthralled by this stupendous book… Caroline is the cleverest writer, and I drifted from country to country and endured those ghastly dogfights and feared and hoped and despaired… honestly a tour de force from start to finish.`,
      name: `Joanna Lumley`,
      book: "italianAffair",
    },
    {
      id: 2,
      review: `An intricate tapestry, woven on the loom of the horrors of war. The needle expertly manipulated by Caroline Montague, weaves stories of love, adventure, surprise and ultimately, good. A thoroughly engrossing read.`,
      name: `Julian Fellowes`,
      book: "italianAffair",
    },
    {
      id: 3,
      review: `‘Intriguing from the start, this meticulously researched novel, moves deftly through time and place in a moving, sweeping saga of love and loss as people and places rebuild in the aftermath of war. A complex, lovely story, with unforgettable characters who endure heartache, but ultimately find courage and forgiveness.`,
      name: `Dinah Jefferies`,
      book: "parisSecret",
    },
    {
      id: 4,
      review: `Enthralling and wonderfully romantic, with gorgeous characters, this is perfect to curl up with and get lost in.`,
      name: `Katie Fforde`,
      book: "parisSecret",
    },
    {
      id: 5,
      review: `Enthralling... snared us in an ever-tightening circle of love and despair, secrets and forgiveness.`,
      name: `Joanna Lumley`,
      book: "spanishSun",
    },
    {
      id: 6,
      review: `Passionate characters with back stories laced with secrets and true historical events, set against the harsh beauty of Andalucía, guarantee hours of escape in this wonderful saga.`,
      name: `Angela Petch`,
      book: "spanishSun",
    },
  ],
  reviews: [
    {
      id: 31,
      title: `Intriguing from the start`,
      review: `Intriguing from the start, this meticulously researched novel, moves deftly through time and place in a moving, sweeping saga of love and loss as people and places rebuild in the aftermath of war. A complex, lovely story, with unforgettable characters who endure heartache, but ultimately find courage and forgiveness.`,
      name: `Dinah Jefferies`,
      book: "parisSecret",
    },
    {
      id: 41,
      title: `Wonderfully romantic`,
      review: `Enthralling and wonderfully romantic, with gorgeous characters, this is perfect to curl up with and get lost in.`,
      name: `Katie Fforde`,
      book: "parisSecret",
    },
    {
      id: 1,
      title: `A thoroughly engrossing read`,
      review: `An intricate tapestry, woven on the loom of horrors of war. The needle, expertly manipulated by Caroline Montague, weaves stories of love, adventure, surprise and ultimately, good. A thoroughly engrossing read.`,
      name: `Julian Fellowes`,
      book: "",
    },
    {
      id: 2,
      title: `A tour de force from start to finish`,
      review: `I was stunned and enthralled by your stupendous book… you are the cleverest writer, and I drifted from country to country and endured those ghastly dogfights and feared and hoped and despaired… honestly a tour de force from start to finish.`,
      name: `Joanna Lumleys`,
      book: "italianAffair",
    },
    {
      id: 3,
      title: `A really good read`,
      review: `I really enjoyed this book. It was well researched and most of it based in Italy from 1937 to after the war. The characters were plausible and likeable. It is a page turner and I recommended it to my bookclub this week and have already sent copes to others. Well done Caroline an excellent novel.`,
      name: `Lavinia de PELET`,
      book: "italianAffair",
    },
    {
      id: 4,
      title: `Wonderful`,
      review: `I was fortunate enough to receive an advanced copy of Caroline’s second novel and I was not disappointed. Caroline has a wonderful ability of transporting you directly to the heart of the action and you can’t help but be swept along with the story - your heart aches for the characters. Caroline’s writing gets better and better with each novel - I can’t wait for the next one!`,
      name: ``,
      book: "parisSecret",
    },
    {
      id: 6,
      title: `COMPELLING`,
      review: `Beautifully written, moving and compelling. You won't be able to put it down.`,
      name: `J.B.`,
      book: "parisSecret",
    },
    {
      id: 7,
      title: `Brilliant read`,
      review: `Perfect book for one of my holiday reads! Love, war, betrayal and so much more.`,
      name: `	Phillippa H.`,
      book: "italianAffair",
    },
    {
      id: 8,
      title: `Faith and love`,
      review: `Well written compelling book.The power of love and faith in humans gives us strength to help overcome the biggest tragedies.`,
      name: `Rita Gennari`,
      book: "italianAffair",
    },
    {
      id: 9,
      title: `Enthralling...`,
      review: `Enthralling... snared us in an ever-tightening circle of love and despair, secrets and forgiveness.`,
      name: `Joanna Lumley`,
      book: "spanishSun",
    },
    {
      id: 10,
      title: `Thoroughly engrossing`,
      review: `Thoroughly engrossing`,
      name: `Julian Fellowes, creator of Downtown Abbey`,
      book: "spanishSun",
    },
    {
      id: 11,
      title: `Passionate Characters`,
      review: `Passionate characters with back stories laced with secrets and true historical events, set against the harsh beauty of Andalucía, guarantee hours of escape in this wonderful saga.`,
      name: `Angela Petch`,
      book: "spanishSun",
    },
  ],
}

export default config
