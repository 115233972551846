import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./style.css"
import { Carousel } from "react-responsive-carousel"

const Reviews = ({ reviews }) => {
  return (
    <div className="py-10 md:py-20 px-2 sm:px-10 md:px-20">
      <div>
        <h3 className="uppercase mb-6 tracking-widest text-2xl text-center font-heading text-heading">
          Reviews
        </h3>
      </div>
      <Carousel
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
      >
        {reviews.map(review => {
          return (
            <div
              key=""
              className="h-56 bg-white flex align-middle flex-col mt-8"
            >
              {review.title && (
                <span className="bookName font-semibold text-xl mb-6 inline-block mx-auto ">
                  {review.title}
                </span>
              )}
              <p className="inline-block max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg text-center mx-auto">
                {review.review}
              </p>
              {review.name && (
                <span className="inline-block max-w-lg text-center mx-auto mt-6">
                  {review.name}
                </span>
              )}
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default Reviews
